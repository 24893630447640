<template>
  <div id="app">
    <!-- <main class="main-content"> -->
      <router-view />
    <!-- </main> -->
  </div>
</template>

<script>

export default {
  name: 'App'
};
</script>

<!-- <style>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa; /* Color de fondo opcional */
}
</style> -->
